import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

// Fetch entities for a specific guest
export const fetchEntities = (guestId) => {
  return axios.get(`${API_URL}/guest/${guestId}/entities`)
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching entities:', error);
      throw error;
    });
};

// Fetch guest booking details
export const fetchGuestDetails = (guestId) => {
  return axios.get(`${API_URL}/guest/${guestId}/guest-details`)
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching guest details:', error);
      throw error;
    });
};

// Toggle the state of a specific entity
export const toggleEntityState = (guestId, entityId, newState) => {
  return axios.post(`${API_URL}/guest/${guestId}/toggle-entity`, { id: entityId, state: newState })
    .then(response => response.data)
    .catch(error => {
      console.error('Error toggling entity state:', error);
      throw error;
    });
};
