// src/pages/Home.js
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const Home = () => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
          loginWithRedirect();
        }
      }, [isLoading, isAuthenticated, loginWithRedirect]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isAuthenticated) {
        return  <Navigate to="/dashboard" />;
    }

    return (
        <div>
            {isAuthenticated ? (
                <p>User is authenticated</p>
            ) : (
                <>
                    <h1>Home Page</h1>
                    <button onClick={() => loginWithRedirect()}>Log in</button>
                </>
                
            )}
        </div>
    );
};

export default Home;
