// src/pages/Dashboard.js
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Sidebar from './Sidebar';

const Dashboard = () => {
  const [bookings, setBookings] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [editingBooking, setEditingBooking] = useState(null);
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const { logout, user, isAuthenticated } = useAuth0();

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar onLogout={logout} collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div style={{ flex: 1, padding: '16px', marginLeft: collapsed ? '0px' : '0px', transition: 'margin-left 0.3s' }}>
        <h1>Dashboard</h1>
        {isAuthenticated ? (
            <>
            <p>Welcome, {user.name}!</p>
            <button onClick={() => logout({ returnTo: window.location.origin })}>
                Log out
            </button>
            </>
        ) : (
            <p>You are not logged in.</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
