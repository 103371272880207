import { IosShare } from '@mui/icons-material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;


const getBookings = (token) => {
  console.debug("Getting Bookings")
  return axios.get(`${API_URL}/bookings`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const createBooking = (token, booking) => {
  console.debug("Creating Booking")
  return axios.post(`${API_URL}/bookings`, booking, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const updateBooking = (token, id, booking) => {
  console.debug("Updating Booking")
  return axios.put(`${API_URL}/bookings/${id}`, booking, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const deleteBooking = (token, id) => {
  console.debug("Deleting Booking")
  return axios.delete(`${API_URL}/bookings/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export default {
  getBookings,
  createBooking,
  updateBooking,
  deleteBooking,
};
