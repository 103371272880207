import { IosShare } from '@mui/icons-material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;


const getAPIKeys = (token) => {
  console.debug("Getting APIKeys")
  return axios.get(`${API_URL}/apikeys`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const createAPIKey = (token, apikey) => {
  console.debug("Creating APIKey")
  return axios.post(`${API_URL}/apikeys`, apikey, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const updateAPIKey = (token, id, apikey) => {
  console.debug("Updating APIKey")
  return axios.put(`${API_URL}/apikeys/${id}`, apikey, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const deleteAPIKey = (token, id) => {
  console.debug("Deleting APIKey")
  return axios.delete(`${API_URL}/apikeys/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export default {
  getAPIKeys,
  createAPIKey,
  updateAPIKey,
  deleteAPIKey,
};
