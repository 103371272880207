import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { SpaceDashboard, EventNote, ExitToApp, Home, ChevronLeft, ChevronRight, Key } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const Sidebar = ({ onLogout }) => {
  const navigate = useNavigate();

  // Retrieve the state from localStorage or default to false (expanded)
  const [collapsed, setCollapsed] = useState(
    () => JSON.parse(localStorage.getItem('sidebarCollapsed')) || false
  );

  // Function to toggle collapse and store the state in localStorage
  const toggleCollapse = () => {
    setCollapsed(prevState => {
      const newState = !prevState;
      localStorage.setItem('sidebarCollapsed', JSON.stringify(newState));
      return newState;
    });
  };

  const handleDashboard = () => {
    navigate('/dashboard');
  };

  const handleManageBookings = () => {
    navigate('/bookings');
  };

  const handleManageRentals = () => {
    navigate('/rentals');
  };

  const handleManageAPIKeys = () => {
    navigate('/apikeys');
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: collapsed ? 60 : drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: collapsed ? 60 : drawerWidth,
          boxSizing: 'border-box',
          transition: 'width 0.3s',
        },
      }}
    >
      <IconButton onClick={toggleCollapse} style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}>
        {collapsed ? <ChevronRight /> : <ChevronLeft />}
      </IconButton>
      <List>
        <ListItem button onClick={handleDashboard}>
          <ListItemIcon>
            <SpaceDashboard />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Dashboard" />}
        </ListItem>
        <ListItem button onClick={handleManageBookings}>
          <ListItemIcon>
            <EventNote />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Bookings" />}
        </ListItem>
        <ListItem button onClick={handleManageRentals}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Rentals" />}
        </ListItem>
        <ListItem button onClick={handleManageAPIKeys}>
          <ListItemIcon>
            <Key />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="API Keys" />}
        </ListItem>
      </List>
      <List style={{ position: 'absolute', bottom: 16 }}>
        <ListItem button onClick={onLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Logout" />}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
