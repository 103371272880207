import React, { useState, useEffect } from 'react';
import bookingService from '../services/bookingService';
import rentalService from '../services/rentalService';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, MenuItem, Select, InputLabel, FormControl, Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Sidebar from './Sidebar';
import moment from 'moment';

const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [editingBooking, setEditingBooking] = useState(null);
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    fetchBookings();
    fetchRentals();
  }, []);

  const fetchBookings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await bookingService.getBookings(token);
      setBookings(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRentals = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getRentals(token);
      setRentals(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async (booking) => {
    try {
      const token = await getAccessTokenSilently();
      await bookingService.createBooking(token, booking);
      fetchBookings();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (booking) => {
    try {
      const token = await getAccessTokenSilently();
      await bookingService.updateBooking(token, editingBooking.id, booking);
      setEditingBooking(null);
      fetchBookings();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      await bookingService.deleteBooking(token, id);
      fetchBookings();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      const token = await getAccessTokenSilently();
      await Promise.all(selectedRows.map(id => bookingService.deleteBooking(token, id)));
      fetchBookings();
      setSelectedRows([]); // Clear selection after deletion
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setEditingBooking(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const booking = {
      rental_id: form.rental_id.value,
      check_in: new Date(`${form.check_in_date.value}T${form.check_in_time.value}:00`).toISOString(),
      check_out: new Date(`${form.check_out_date.value}T${form.check_out_time.value}:00`).toISOString(),
      guest_name: form.guest_name.value,
      guest_email: form.guest_email.value,
      phone_number: form.phone_number.value,
      pincode: form.pincode.value,
    };
    if (editingBooking) {
      handleUpdate(booking);
    } else {
      handleCreate(booking);
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const columns = [
    { field: 'rental_name', headerName: 'Rental Name', width: 150 },
    { field: 'check_in', headerName: 'Check-in', width: 300, },
    { field: 'check_out', headerName: 'Check-out', width: 300, },
    { field: 'guest_name', headerName: 'Guest Name', width: 150 },
    { field: 'guest_email', headerName: 'Guest Email', width: 200 },
    { field: 'phone_number', headerName: 'Phone Number', width: 150 },
    { field: 'pincode', headerName: 'Pin Code', width: 100 },
    { field: 'guest_dashboard_link', headerName: 'Dashboard Link', width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => { setEditingBooking(params.row); setOpen(true); }}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = bookings.map((booking) => ({
    id: booking.id,
    rental_id: booking.rental_id,
    rental_name: booking.rental.rental_name,
    check_in: moment(booking.check_in),
    check_out: moment(booking.check_out),
    guest_name: booking.guest_name,
    guest_email: booking.guest_email,
    phone_number: booking.phone_number,
    pincode: booking.pincode,
    guest_dashboard_link: booking.guest_dashboard_link,
  }));

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar onLogout={logout} collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '16px', transition: 'margin-left 0.3s' }}>
        <h2>Bookings</h2>
        <div style={{ marginBottom: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginRight: '8px' }}>
            Add Booking
          </Button>
          <Button variant="contained" color="secondary" onClick={handleDeleteSelected} disabled={selectedRows.length === 0}>
            Delete Selected
          </Button>
        </div>
        <Paper style={{ flex: 1, overflow: 'auto' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
            selectionModel={selectedRows}
            components={{ Toolbar: GridToolbar }}
            style={{ minHeight: 0, width: '100%' }}
          />
        </Paper>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>{editingBooking ? 'Edit Booking' : 'Add Booking'}</DialogTitle>
          <DialogContent>
            <Box component="form" id="booking-form" onSubmit={handleFormSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: '500px' }}>
            <FormControl fullWidth margin="normal">
            <InputLabel>Rental</InputLabel>
            <Select
              name="rental_id"
              value={editingBooking ? editingBooking.rental_id : ''}
              onChange={(e) => setEditingBooking({ ...editingBooking, rental_id: e.target.value })}
              required
            >
              {rentals.map((rental) => (
                <MenuItem key={rental.id} value={rental.id}>
                  {rental.rental_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
              <TextField
                name="check_in_date"
                label="Check-in Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={editingBooking ? moment(editingBooking.check_in).format('YYYY-MM-DD') : ''}
                required
              />
              <TextField
                name="check_in_time"
                label="Check-in Time"
                type="time"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={editingBooking ? moment(editingBooking.check_in).format('HH:mm') : '16:00'}
                required
              />
              <TextField
                name="check_out_date"
                label="Check-out Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={editingBooking ? moment(editingBooking.check_out).format('YYYY-MM-DD') : ''}
                required
              />
              <TextField
                name="check_out_time"
                label="Check-out Time"
                type="time"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={editingBooking ? moment(editingBooking.check_out).format('HH:mm') : '11:00'}
                required
              />
              <TextField
                name="guest_name"
                label="Guest Name"
                fullWidth
                margin="normal"
                defaultValue={editingBooking ? editingBooking.guest_name : ''}
                required={true}
              />
              <TextField
                name="guest_email"
                label="Guest Email"
                fullWidth
                margin="normal"
                defaultValue={editingBooking ? editingBooking.guest_email : ''}
                required={false}
              />
              <TextField
                name="phone_number"
                label="Phone Number"
                fullWidth
                margin="normal"
                defaultValue={editingBooking ? editingBooking.phone_number : ''}
                required={false}
              />
              <TextField
                name="pincode"
                label="Pin Code"
                fullWidth
                margin="normal"
                defaultValue={editingBooking ? editingBooking.pincode : ''}
                required
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button form="booking-form" type="submit" color="primary">
              {editingBooking ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default BookingList;
