import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import rentalService from '../services/rentalService';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Sidebar from './Sidebar';

const RentalList = () => {
  const [rentals, setRentals] = useState([]);
  const [editingRental, setEditingRental] = useState(null);
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    fetchRentals();
  }, []);

  const fetchRentals = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getRentals(token);
      setRentals(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async (rental) => {
    try {
      const token = await getAccessTokenSilently();
      await rentalService.createRental(token, rental);
      fetchRentals();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (rental) => {
    try {
      const token = await getAccessTokenSilently();
      rental.home_assistant_config.id = editingRental.home_assistant_config_id
      await rentalService.updateRental(token, editingRental.id, rental);
      setEditingRental(null);
      fetchRentals();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      await rentalService.deleteRental(token, id);
      fetchRentals();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      const token = await getAccessTokenSilently();
      await Promise.all(selectedRows.map(id => rentalService.deleteRental(token, id)));
      fetchRentals();
      setSelectedRows([]); // Clear selection after deletion
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setEditingRental(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const rental = {
      rental_name: form.rental_name.value,
      address: form.address.value,
      home_assistant_config: {
        host: form.home_assistant_host.value,
        api_key: form.home_assistant_api_key.value
      }
    };
    if (editingRental) {
      handleUpdate(rental);
    } else {
      handleCreate(rental);
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };


  const columns = [
    { field: 'rental_name', headerName: 'Rental Name', width: 150 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'home_assistant_host', headerName: 'Home Assistant Host', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => { setEditingRental(params.row); setOpen(true); }}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = rentals.map((rental) => ({
    id: rental.id,
    rental_name: rental.rental_name,
    address: rental.address,
    home_assistant_config_id: rental.home_assistant_config.id, 
    home_assistant_host: rental.home_assistant_config.host,
  }));

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar onLogout={logout} collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '16px', transition: 'margin-left 0.3s' }}>
        <h2>Rentals</h2>
        <div style={{ marginBottom: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginRight: '8px' }}>
            Add Rental
          </Button>
          <Button variant="contained" color="secondary" onClick={handleDeleteSelected} disabled={selectedRows.length === 0}>
            Delete Selected
          </Button>
        </div>
        <Paper style={{ flex: 1, overflow: 'auto' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
            selectionModel={selectedRows}
            components={{ Toolbar: GridToolbar }}
            style={{ minHeight: 0, width: '100%' }}
          />
        </Paper>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>{editingRental ? 'Edit Rental' : 'Add Rental'}</DialogTitle>
          <DialogContent>
            <Box component="form" id="rental-form" onSubmit={handleFormSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: '500px' }}>
              <TextField
                name="rental_name"
                label="Rental Name"
                fullWidth
                margin="normal"
                defaultValue={editingRental ? editingRental.rental_name : ''}
                required
              />
              <TextField
                name="address"
                label="Address"
                fullWidth
                margin="normal"
                defaultValue={editingRental ? editingRental.address : ''}
                required
              />
              <TextField
                name="home_assistant_host"
                label="Home Assistant Host"
                fullWidth
                margin="normal"
                defaultValue={editingRental ? editingRental.home_assistant_host : ''}
              />
              <TextField
                name="home_assistant_api_key"
                label="Home Assistant API Key"
                fullWidth
                margin="normal"
                defaultValue={editingRental ? editingRental.home_assistant_api_key : ''}
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button form="rental-form" type="submit" color="primary">
              {editingRental ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default RentalList;
